<template>
  <v-row justify="center">
    <v-slide-y-transition appear>
      <template v-slot:heading>
        <div class="text-center">
          <h1 class="display-2 font-weight-bold mb-2">Login</h1>
        </div>
      </template>

      <v-card-text class="text-center card">
        <v-card-title class="justify-center title-card">
          <v-icon large color="red">vagas-home</v-icon>
          <h2>Seja bem-vindo!</h2>
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="flex-column" align="start">
            <v-col class="d-flex flex-column align-start py-0">
              <span class="text-fields">E-mail</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="username"
                placeholder="willie.jennings@example.com"
                :rules="[(v) => !!v || 'E-mail requerido']"
                @keydown.enter="login"
                color="secondary"
                :messages="msgLabel"
                required
                outlined
                rounded
                autocomplete="email"
                class="input"
              />
            </v-col>
          </v-row>

          <v-row class="flex-column senha" align="start">
            <v-col class="d-flex flex-column align-start py-0">
              <span class="text-fields">Senha</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password"
                placeholder="********"
                :rules="passwordRules"
                @keydown.enter="login"
                color="secondary"
                required
                outlined
                rounded
                class="input"
                autocomplete="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-btn
          :disabled="!valid"
          large
          rounded
          color="success"
          @click="login"
          :loading="loading"
          class="my-4 btn-login py-3"
        >
          <p style="color: white; margin-bottom: 0px">entrar</p>
        </v-btn>

        <br />

        <v-btn class="forget-button" text small @click="toForgot">
          Esqueceu a senha?
        </v-btn>
        <!--br />
        <span class="register">
          Não possui conta?
          <span class="register-btn" @click="toRegister">Cadastre-se</span>
        </span-->
      </v-card-text>
    </v-slide-y-transition>
  </v-row>
</template>

<style scoped>
.text-fields {
  font-size: 1.2em;
}
.card {
  padding: 1.5rem 1.5rem 1.5rem !important;
  width: 400px;
}
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}
.senha {
  margin-top: 0 !important;
}
</style>

<script>
import ApiService from "../../../services/ApiService";

export default {
  name: "Login",

  components: {},

  data: () => ({
    show: false,
    apiService: new ApiService(),
    loading: false,
    error: false,
    valid: true,
    documentNumber: "",
    documentNumberRules: [(v) => !!v || "CPF requerido"],
    password: "",
    passwordRules: [(v) => !!v || "Senha requerida"],
    username: undefined,
    password: undefined,
    msgLabel: "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    toForgot() {
      this.$router.push("/auth/forgot-password");
    },
    toRegister() {
      this.$router.push({ name: "escolhaPerfil" });
    },

    async login() {
      if (!this.validate()) { return; }
      const user = {
        Email: this.username,
        Password: this.password,
      };

      this.msgLabel = "";
      this.loading = true;

      try {

        let req = await this.apiService.post("user/token/kristal", user);
        if (req.content.isExpired) {
          this.$router.push("/auth/update-password");
          return;
        }
        let userId =req.content.user.id;
        let userLogged = JSON.stringify(req.content.user);
        let token = req.content.token;
        let validTo = req.content.validTo;
        this.apiService.setCotacoes(req.content.cotacoes);

        this.$store.commit("SET_LOGGED_USER", userLogged);
        this.$store.commit("SET_TOKEN_USER_SELECTED", userId);
        this.$store.commit("SET_USER_SELECTED",userLogged);

        this.$store.commit("SET_CURRENT_TOKEN", token);
        this.$store.commit("SET_EXPIRES_TOKEN", validTo);
        this.$store.dispatch("PUT_PLAN_TYPE", req.content.user.planType || 0);
        sessionStorage.removeItem("userId");
        if (req.content.user.userProfile == 0) {
          this.$router.push("/visao-geral");
        } else {
          this.$router.push("/gestao-usuario");
        }
        this.loading = false;
      } catch (reason) {
        //console.log(reason);
        this.msgLabel=reason && reason.body && reason.body.message || 'Erro não identificado';
        this.error = true;
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("expires");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("tokenUserSelected");
        this.loading = false;
        setTimeout(() => (this.error = false), 3000);
        // this.$toast.error('Erro ao tentar fazer o login')
      }
    },
  },
};
</script>
