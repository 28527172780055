var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { id: "auth" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-5",
              attrs: { elevation: "10", color: "#ffffff" },
            },
            [_c("Login")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }